import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { signUp } from "../../../utils/auth";
import "./sign-up.scss";

import RedirectOnSuccess from "./redirect-on-success/redirect-on-success";
import LLCardGeneric from "../../misc/ll-card-generic/ll-card-generic";
import LLSpinnerBasic from "../../misc/ll-spinner-basic/ll-spinner-basic";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpError: null,
      signingUp: false,
      signUpSuccess: false,
    };
  }

  async handlesignUp(event) {
    try {
      this.setState({ signingUp: true });

      const { email, password, confirmPassword } = event;

      const { signUpSuccess, errorMessage } = await signUp(
        email,
        password,
        confirmPassword
      );

      if (!signUpSuccess) {
        throw Error(errorMessage);
      }

      setTimeout(() => {
        this.setState({ signUpSuccess });
      }, 500);
    } catch (e) {
      console.error(e);
      if (e.code === "UsernameExistsException") {
        this.setState({
          signUpError: "Ya existe una cuenta con este correo electrónico.",
        });
        return;
      }
      this.setState({ signUpError: e.message });
      return "";
    }
  }

  handleSignUpWorkflow() {
    const { signUpError, signUpSuccess, signingUp } = this.state;
    if (signingUp) {
      if (!signUpSuccess) {
        return (
          <LLCardGeneric
            title={"Creando la cuenta..."}
            content={<LLSpinnerBasic size="24" />}
          />
        );
      }
      return <RedirectOnSuccess />;
    }

    if (signUpError) {
      return (
        <div className="sign-up-error">
          <h1>Diay si...</h1>
          <p>{signUpError}</p>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="generic-btn"
          >
            Volver
          </button>
        </div>
      );
    }
  }

  render() {
    const { signingUp } = this.state;

    if (signingUp) {
      return this.handleSignUpWorkflow();
    }
    return (
      <div className="sign-up-form-container">
        <Formik
          onSubmit={this.handlesignUp.bind(this)}
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("El email ingresado es inválido.")
              .required("Ingrese su correo electónico"),
            password: Yup.string()
              .min(6, "La contraseña debe tener 6 o más caracteres")
              .matches(
                "(.*[a-z].*)",
                "La contraseña debe contener 1 o más minúsculas"
              )
              .matches(
                "(.*[A-Z].*)",
                "La contraseña debe contener 1 o más mayúsculas"
              )
              .matches(
                "(.*[0-9].*)",
                "La contraseña debe contener 1 o más números"
              )
              .required("Ingrese una contraseña"),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                "Las contraseñas no coinciden"
              )
              .required("Confirme su contraseña"),
          })}
        >
          {({ errors, status, touched }) => (
            <div className="sign-up-form-wrapper">
              <h1 className="sign-up-form-header">Crear Usuario</h1>
              <Form className="sign-up-form">
                <div className="sign-up-form-group">
                  <label className="sign-up-label" htmlFor="email">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      "sign-up-form-control" +
                      (errors.email && touched.email
                        ? " sign-up-field-is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="sign-up-invalid-feedback"
                  />
                </div>
                <div className="sign-up-form-group">
                  <label className="sign-up-label" htmlFor="password">
                    Contraseña
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "sign-up-form-control" +
                      (errors.password && touched.password
                        ? " sign-up-field-is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="sign-up-invalid-feedback"
                  />
                </div>
                <div className="sign-up-form-group">
                  <label className="sign-up-label" htmlFor="confirmPassword">
                    Confirmar contraseña
                  </label>
                  <Field
                    name="confirmPassword"
                    type="password"
                    className={
                      "sign-up-form-control" +
                      (errors.confirmPassword && touched.confirmPassword
                        ? " sign-up-field-is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="sign-up-invalid-feedback"
                  />
                </div>
                <div className="sign-up-btn-form-group">
                  <button type="reset" className="los-litros-generic-btn">
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="los-litros-generic-btn los-litros-success-btn"
                  >
                    Registrarse
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

export default SignUp;
