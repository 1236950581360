import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { signIn } from "../../../utils/auth";
import LLCardGeneric from "../../misc/ll-card-generic/ll-card-generic";
import LLSpinnerBasic from "../../misc/ll-spinner-basic/ll-spinner-basic";

import "./sign-in.scss";

const SignIn = () => {
  const signInBtnRef = useRef();

  const [customerData, setCustomerData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigninIn] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);

  // useEffect(() => {

  // });

  const handleOnChange = (setIdentifierState, event) => {
    !signingIn && !redirectToSignUp && validForm();
    setIdentifierState(event.target.value);
  };

  const validForm = () => {
    if (email && password && email.length > 4 && password.length > 7) {
      signInBtnRef.current.disabled = false;
    } else {
      signInBtnRef.current.disabled = true;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setSigninIn(true);
    let { email, password } = e.target;
    try {
      const signInMeta = await signIn(email.value, password.value);
      setCustomerData({
        customerId: signInMeta.username,
        email: signInMeta.attributes.email,
      });
    } catch (error) {
      setSignInError(error.message);
    }
  };

  const signInForm = (
    <form className="sign-in-form" onSubmit={handleOnSubmit}>
      <label>Email</label>
      <input
        type="text"
        name="email"
        value={email}
        onChange={handleOnChange.bind(this, setEmail)}
      />
      <label>Contraseña</label>
      <input
        type="password"
        name="password"
        value={password}
        onChange={handleOnChange.bind(this, setPassword)}
      />
      <div className="sign-in-btn-group">
        <button
          type="button"
          onClick={() => setRedirectToSignUp(true)}
          className="generic-btn los-litros-generic-btn"
        >
          Registrarse
        </button>
        <input
          className="los-litros-generic-btn los-litros-success-btn sign-in-btn"
          type="submit"
          value="Iniciar Sesión"
          ref={signInBtnRef}
          disabled
        />
      </div>
    </form>
  );

  const handleSignInWorkflow = () => {
    let render = { title: "", content: <></>, isAuthorized: false };

    if (signingIn) {
      if (signInError) {
        render.title = "Diay si...";
        render.content = signInError;

        if (signInError === "Su correo aún no ha sido verificado") {
          render.content = (
            <div>
              {signInError}
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="los-litros-generic-btn"
              >
                volver
              </button>
              <button className="los-litros-generic-btn los-litros-warn-btn">
                renviar código
              </button>
            </div>
          );
        }
        if (signInError === "Información incorrecta, intente de nuevo") {
          render.content = (
            <div className="signing-in-content">
              {signInError}
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="los-litros-generic-btn"
              >
                volver
              </button>
            </div>
          );
        }
      } else {
        render.title = "Suave un toque...";
        render.content = <LLSpinnerBasic />;
      }
    } else {
      render.title = "Iniciar Sesión";
      render.content = signInForm;
    }
    return render;
  };

  const { title, content } = handleSignInWorkflow();

  if (customerData) {
    console.log(customerData);
    return (
      <Redirect
        to={{
          pathname: "/customer-loyalty",
          state: { customerData },
        }}
      />
    );
  }
  if (redirectToSignUp) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <LLCardGeneric
      className="sign-in-container"
      title={title}
      content={content}
    />
  );
};

export default SignIn;
