import React from "react";

import "./ll-spinner-basic.scss";
const LLSpinnerBasic = ({ size }) => {
  return (
    <svg
      className={"lds-ripple"}
      viewBox="0 0 100 100"
      width={size || "32"}
      height={size || "32"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" />
    </svg>
  );
};

export default LLSpinnerBasic;
