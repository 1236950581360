import { Auth } from "aws-amplify";

async function signUp(email, password, confirmPassword) {
  try {
    if (!email) {
      throw Error("Debe ingresar un correo electrónico.");
    }
    if (!password) {
      throw Error("Debe ingresar una contraseña.");
    }
    if (!confirmPassword) {
      throw Error("Debe confirmar la contraseña.");
    }
    if (password !== confirmPassword) {
      throw Error("Las contraseñas ingresadas no coinciden.");
    }

    await Auth.signUp({
      username: email,
      password,
    });

    return { signUpSuccess: true };
  } catch (error) {
    console.error(error);
    if (error.code === "UsernameExistsException") {
      throw Error("Ya existe una cuenta con el correo proporcionado.");
    }
    throw Error("No se pudo registrar la cuenta, por favor intente de nuevo");
  }
}

async function signIn(email, password) {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      throw Error("Su correo aún no ha sido verificado");
    }
    if (error.code === "UserNotFoundException") {
      throw Error("No existe una cuenta con este correo");
    }
    if (error.code === "NotAuthorizedException") {
      throw Error("Información incorrecta, intente de nuevo");
    }
    throw Error("No se pudo iniciar sesión con esta cuenta, por favor intente de nuevo");
  }
}

export { signUp, signIn };
