import React from "react";
import "./los-litros-brand-min.scss";

const LosLitrosBrandMin = () => {
  return (
    <img
      className="los-litros-brand-min"
      src="assets/svg/logo-minimal-los-litros.svg"
      alt="El logo de Los Litros"
    />
  );
};

export default LosLitrosBrandMin;
