import React from "react";
import "./whatsapp-contact-us-banner.scss";

const WhatsappContactUsBanner = () => {
  return (
    <div className="whatsapp-contact-us-banner">
      <a
        className="whatsapp-contact-us-btn"
        target="_blank"
        rel="noopener"
        href=" https://wa.me/50662351859?text=¡Hola,%20hoy%20quiero%20quedar%20Hasta%20La%20Mera!"
      >
        <img
          className="whatsapp-contact-logo"
          src="assets/svg/whatsapp_contact.svg"
          alt="Ordena por WhatsApp"
        />
        ORDENÁ POR WHATSAPP
      </a>
    </div>
  );
};

export default WhatsappContactUsBanner;
