import React from "react";
import { Link } from "react-router-dom";
import "./redirect-on-success.scss";

import LLCardGeneric from "../../../misc/ll-card-generic/ll-card-generic";

const RedirectOnSuccess = () => {
  const redirectOnSuccessCardContent = (
    <>
      <div className="redirect-on-success-message">
        Te mandamos un correo con un enlace para que activés tu cuenta!
      </div>
      <div className="los-litros-generic-btn redirect-on-success-btn">
        <Link to="/sign-in">Iniciar Sesión</Link>
      </div>
    </>
  );

  return <LLCardGeneric title="OK" content={redirectOnSuccessCardContent} />;
};

export default RedirectOnSuccess;
