import React from "react";
import "./ll-card-generic.scss";

const LLCardGeneric = ({ title, content, titleClass }) => {
  return (
    <div className="ll-card-container">
      <div className="ll-card-wrapper">
        <h1 className={`ll-card-title ${titleClass}`}>{title}</h1>
        <div className="ll-card-content">{content}</div>
      </div>
    </div>
  );
};

export default LLCardGeneric;
