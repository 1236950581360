import React from "react";
import "./wrapper.scss";

import Menu from "../menu/menu";
// import SinpeBanner from "../banners/sinpe-banner/sinpe-banner";
import WhatsappContactUsBanner from "../banners/whatsapp-contact-us-banner/whatsapp-contact-us-banner";
import LosLitrosBrandMin from "../misc/los-litros-brand/los-litros-brand-min";

const Wrapper = () => {
  return (
    <div className="wrapper">
      <Menu />
      {/* <SinpeBanner /> */}
      <WhatsappContactUsBanner />
      <LosLitrosBrandMin />
    </div>
  );
};

export default Wrapper;
