import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./global-styles.scss";
import Wrapper from "./components/wrapper/wrapper";
import SignUp from "./components/auth/sign-up/sign-up";
import SignIn from "./components/auth/sign-in/sign-in";
import CustomerLoyalty from "./components/customer-loyalty/customer-loyalty";

function App() {
  console.log("For more info contact segura2794@gmail.com");
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Wrapper />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route
          path="/customer-loyalty"
          render={(props) => <CustomerLoyalty {...props} />}
        />
        <Route
          path="/admin"
          render={(props) => <h1>WAO ADMIN</h1>}
        />
      </Switch>
    </Router>
  );
}

export default App;
