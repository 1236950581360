import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import aws from "aws-sdk";
import moment from "moment";
import localization from "moment/locale/es";
import "./customer-loyalty.scss";
import LLCardGeneric from "../misc/ll-card-generic/ll-card-generic";
import LLSpinnerBasic from "../misc/ll-spinner-basic/ll-spinner-basic";

const CustomerLoyalty = (props) => {
  const [email, setEmail] = useState();
  const [cxWallet, setCxWallet] = useState();

  useEffect(() => {
    if (props.location.state) {
      const { customerData } = props.location.state;
      if (!cxWallet) {
        getCxWallet(customerData.customerId);
        setEmail(customerData.email);
      }
    }
  }, [props.location.state, cxWallet]);

  const getCxWallet = async (cxId) => {
    const cxData = (
      await axios.get(
        `https://59bkm4s3c0.execute-api.us-west-2.amazonaws.com/beta/cx-loyalty/${cxId}`
      )
    ).data.Item;

    setCxWallet(aws.DynamoDB.Converter.unmarshall(cxData));
  };

  if (props.location.state && props.location.state.customerData) {
    if (cxWallet) {
      console.log("-*->", cxWallet);
      const cxWalletComp = (
        <div className="cx-wallet-wallet-component">
          <div className="cx-wallet-label">Cliente</div>
          <span className="cx-wallet-input" role="textbox" disabled>
            {email}
          </span>
          <div className="cx-wallet-label">Estado</div>
          <span className="cx-wallet-input" role="textbox" disabled>
            {cxWallet.active ? "Activa" : "Inactiva"}
          </span>
          <div className="cx-wallet-label">Balance</div>
          <span className="cx-wallet-input" role="textbox" disabled>
            {cxWallet.balance} puntos
          </span>
          <div className="cx-wallet-label">Cuenta Creada</div>
          <span className="cx-wallet-input" role="textbox" disabled>
            {moment(cxWallet.created_on)
              .locale("es", localization)
              .format("D [de] MMMM[, ] YYYY")}
          </span>
        </div>
      );

      return (
        <div className="customer-loyalty-container">
          <LLCardGeneric
            title="Tarjeta de Cliente Frecuente"
            content={cxWalletComp}
          />
        </div>
      );
    }
    return <LLCardGeneric title="Ya casi..." content={<LLSpinnerBasic />} />;
  }
  return <Redirect to="/sign-in" />;
};

export default CustomerLoyalty;
